<template>
  <div class="el_financial">
    <banner />
    <div class="mrow rmt">
      <div class="wrapper">
        <sidebar />
        <div class="mline"></div>
        <hotspot />
        <broadcast />
      </div>
    </div>

    <resources />
  </div>
</template>

<script>
import banner from "./banner.vue";
import hotspot from "./hotspot.vue";
import broadcast from "./broadcast.vue";
import resources from "./resources.vue";
import sidebar from "./sidebar.vue";
export default {
  data() {
    return {
      msg: "el_financial",
    };
  },
  components: {
    banner,
    hotspot,
    broadcast,
    resources,
    sidebar,
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
