var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hnews"},[_c('div',{staticClass:"hnews-box left"},[_vm._m(0),_c('div',{staticClass:"hnews-bd mnews-bd"},[_c('ul',{staticClass:"mnewslist"},_vm._l((_vm.mediaList),function(item){return _c('li',{key:item.id},[_c('a',{on:{"click":function($event){return _vm.toDetail('article', item)}}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.photo}})]),_c('div',{staticClass:"txts"},[_c('h3',{staticClass:"tit"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.summary)+" ")]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.releaseTime))])])])])}),0),_c('div',{staticClass:"more"},[_c('a',{on:{"click":function($event){return _vm.toRouter('information', {
              type: 1,
              title: '热点活动',
              en: 'exciting activities',
            })}}},[_vm._v("更多活动")])])])]),_c('div',{staticClass:"hnews-box right"},[_vm._m(1),_c('div',{staticClass:"hnews-bd mnews-bd"},[_c('ul',{staticClass:"mnewslist"},_vm._l((_vm.list),function(item){return _c('li',{key:item.id},[_c('a',{on:{"click":function($event){return _vm.toDetail('article', item)}}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.photo}})]),_c('div',{staticClass:"txts"},[_c('h3',{staticClass:"tit"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.summary)+" ")]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.releaseTime))])])])])}),0),_c('div',{staticClass:"more"},[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.toRouter('information', {
              type: 2,
              title: '热点资讯',
              en: 'Popular informationes',
            })}}},[_vm._v("更多新闻")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mtit hnews-hd"},[_c('h2',{staticClass:"cn"},[_vm._v("热点活动")]),_c('div',{staticClass:"en"},[_vm._v("Popular Event")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mtit hnews-hd"},[_c('h2',{staticClass:"cn"},[_vm._v("热点新闻")]),_c('div',{staticClass:"en"},[_vm._v("Popular news")])])}]

export { render, staticRenderFns }