<template>
  <div class="hnews">
    <div class="hnews-box left">
      <div class="mtit hnews-hd">
        <h2 class="cn">热点活动</h2>
        <div class="en">Popular Event</div>
      </div>
      <div class="hnews-bd mnews-bd">
        <ul class="mnewslist">
          <li v-for="item in mediaList"
              :key="item.id">
            <a @click="toDetail('article', item)">
              <div class="img">
                <img :src="item.photo" />
              </div>
              <div class="txts">
                <h3 class="tit">
                  {{ item.title }}
                </h3>
                <div class="info">
                  {{ item.summary }}
                </div>
                <div class="time">{{ item.releaseTime }}</div>
              </div>
            </a>
          </li>
        </ul>
        <div class="more">
          <a @click="
              toRouter('information', {
                type: 1,
                title: '热点活动',
                en: 'exciting activities',
              })
            ">更多活动</a>
        </div>
      </div>
    </div>
    <div class="hnews-box right">
      <div class="mtit hnews-hd">
        <h2 class="cn">热点新闻</h2>
        <div class="en">Popular news</div>
      </div>
      <div class="hnews-bd mnews-bd">
        <ul class="mnewslist">
          <li v-for="item in list"
              :key="item.id">
            <a @click="toDetail('article', item)">
              <div class="img">
                <img :src="item.photo" />
              </div>
              <div class="txts">
                <h3 class="tit">
                  {{ item.title }}
                </h3>
                <div class="info">
                  {{ item.summary }}
                </div>
                <div class="time">{{ item.releaseTime }}</div>
              </div>
            </a>
          </li>
        </ul>
        <div class="more">
          <a @click="
              toRouter('information', {
                type: 2,
                title: '热点资讯',
                en: 'Popular informationes',
              })
            "
             target="_blank">更多新闻</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      list: [],
      mediaList: [],
    };
  },
  mounted () {
    this.getTabArticleList();
    this.getMediaList();
  },
  methods: {
    toRouter (routerName, item) {
      // document.body.scrollTop = document.documentElement.scrollTop = 0
      let query = {};
      // 党建要闻
      query = {
        type: item.type,
        title: item.title,
        en: item.en,
        name: '融媒体'
      };

      this.newRouter("/" + routerName, query);
    },
    toDetail (path, data) {
      // this.tongji(data.title)
      console.log(data, 'ddddddddd')
      this.api.tongji({ user_id: 26, site: 53, Title: data.title, url: 'article' }).then((res) => {
        console.log(res, 998888)
        newRouter("/" + path, {
          id: data.id,
          name: 2,
        });
      });
    },
    tongji (title) { // origin + '/#/article'
      this.api.tongji({ user_id: 26, site: 53, Title: title, url: 'article' }).then((res) => {
        console.log(res, 998888)
      });
    },
    getTabArticleList () {
      this.api
        .getMediaList({ pageNo: 1, pageSize: 50, mediaType: 2 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.list = records;
        });
    },
    getMediaList () {
      this.api
        .getMediaList({ pageNo: 1, pageSize: 50, mediaType: 1 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.mediaList = records;
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
