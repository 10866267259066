<template>
  <div class="kjzs">
    <div class="mtit1">
      <h2 class="cn">空间展示</h2>
      <div class="en">Space display</div>
      <div class="line"></div>
    </div>
    <div class="kjzs-list">
      <div class="swiper-container">
        <ul class="swiper-wrapper">
          <li class="swiper-slide"
              v-for="item in pictureList"
              :key="item.id">
            <a>
              <img :src="item.pic" />
              <h3 class="tit">{{ item.title }}</h3>
            </a>
          </li>
        </ul>
      </div>
      <div class="swiper-button-prev kjzs-prev"></div>
      <div class="swiper-button-next kjzs-next"></div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
import Swiper from "@/libs/js/swiper.min";
export default {
  data () {
    return {
      pictureList: [],
    };
  },
  mounted () {
    this.getPictureList();
    window.onresize = () => {
      if (document.body.offsetWidth < 1201) {
        this.$nextTick(() => {
          this.swiperStart(1)
        })
      } else {
        this.$nextTick(() => {
          this.swiperStart(4)
        })
      }
    }
  },
  methods: {
    toRouter (param) {
      newRouter(param);
    },
    swiperStart (num) {
      new Swiper(".kjzs-list .swiper-container", {
        slidesPerView: num || 4,
        spaceBetween: 30,
        loop: true,
        autoplayDisableOnInteraction: false,
        autoplay: 4000,
        nextButton: ".kjzs-next",
        prevButton: ".kjzs-prev",
      });
    },
    getPictureList () {
      this.api
        .getPictureList({ pageNo: 1, pageSize: 50, type: 8 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.pictureList = records;
          setTimeout(() => {
            if (document.body.offsetWidth < 1201) {
              this.$nextTick(() => {
                this.swiperStart(1)
              })
            } else {
              this.$nextTick(() => {
                this.swiperStart(4)
              })
            }
          }, 300);
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
