<template>
  <div>
    <div class="hnews pt40">
      <div class="mtit hnews-hd">
        <h2 class="cn">热点直播</h2>
        <div class="en">live streaming</div>
      </div>
      <div class="hnews-bd mnews-bd">
        <ul class="mnewslist mnewslist2">
          <li v-for="item in mediaList"
              :key="item.id">
            <a @click="toDetail('article', item)">
              <div class="img">
                <img :src="item.photo" />
              </div>
              <div class="txts">
                <h3 class="tit">
                  {{ item.title }}
                </h3>
                <div class="info">
                  {{ item.summary }}
                </div>
                <div class="time">{{ item.releaseTime }}</div>
              </div>
            </a>
          </li>
        </ul>
        <div class="more">
          <a @click="
              toRouter('information', {
                type: 3,
                title: '热点直播',
                en: 'NEWS',
              })
            ">更多直播</a>
        </div>
      </div>
    </div>

    <div class="kjzs-btn">
      <a @click="toRouter('apply',{cn:'申请合作',en:'Apply for cooperation',type:4})"
         class="mfullbtn"><img src="../../../assets/images/icon34.png" />申请合作</a>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      mediaList: [],
    };
  },
  mounted () {
    this.getMediaList();
  },
  methods: {
    toRouter (routerName, item) {
      let query = {};
      if (routerName == 'apply') {
        query = {
          ...item
        }
      } else {
        query = {
          type: item.type,
          title: item.title,
          en: item.en,
          name: '融媒体'
        };
      }

      this.newRouter("/" + routerName, query);
    },
    toDetail (path, data) {
      this.api.tongji({ user_id: 26, site: 53, Title: data.title, url: 'article' }).then((res) => {
        console.log(res, 998888)
        newRouter("/" + path, {
          id: data.id,
          name: 2,
        });
      });
      // newRouter("/" + path, {
      //   id: data.id,
      //   name: 2,
      // });
    },
    getMediaList () {
      this.api
        .getMediaList({ pageNo: 1, pageSize: 10, mediaType: 3 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.mediaList = records;
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
