<template>
  <div class="mrow hzmt">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">合作媒体资源</h2>
        <div class="en">Cooperative media resources</div>
        <div class="line"></div>
      </div>
      <div class="hzmt-list">
        <ul>
          <li v-for="item in list"
              :key="item.id">
            <a>
              <div class="img">
                <img :src="item.logo" />
              </div>
              <div class="info">
                <h3 class="tit">{{ item.companyName }}</h3>
                <div class="txt">
                  {{ item.companyContent }}
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: [],
    };
  },
  mounted () {
    this.getMediaInfo();
  },
  methods: {
    getMediaInfo () {
      this.api.getMediaInfo({ pageNo: 1, pageSize: 100 }).then((res) => {
        const {
          data: { result },
        } = res;
        const { records } = result || [];
        this.list = records;
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
