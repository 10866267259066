<template>
<div class="mbanner">
	<div class="wrapper">
		<div class="mbanner-hd">
			<div class="cn"><h2>融媒体</h2></div>
			<div class="en">Convergent Media</div>
		</div>
		<div class="mbanner-bd mbanner-bd1">
			满足后疫情时期人社公共服务现实需要，全力做好“六稳”工作、落实“六保”任务，<br />
以及为了更好、更广泛、常态化地开展网络人社公共服务、<br />
网络人力资源服务。主要用于产业园内各人社公共服务部门、<br />
人力资源服务机构开展网络直播招人招才、<br />
直播人社政策解读、直播职业指导、在线培训等服务。
		</div>
	</div>
</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner08.jpg);
}
</style>
